import React from 'react'
import {Card, CardHeader} from '@nextui-org/react'
import {VmView} from '@/components'

const Page404 = (props: any) => {
  return (
    <VmView className="absolute inset-0 flex justify-center items-center bg-wht-fbfbfb-50">
      <Card className="p-3">
        <CardHeader className="justify-between">
          <VmView className="flex gap-5">
            <VmView className="flex flex-col gap-1 items-start justify-center">
              <h2 className="text-lg font-semibold leading-none text-default-600">
                404 Not Found!
              </h2>
              <h5 className="text-sm tracking-tight text-default-400">
                Please contact support if you see this page.
              </h5>
            </VmView>
          </VmView>
        </CardHeader>
      </Card>
    </VmView>
  )
}
export default Page404
